const mixin = {
  data() {
    return {
      status: "",
      label: "",
    };
  },
  methods: {
    getAssetStatus(status) {
      switch (status) {
        case 0:
          return "未下载";
        case 1:
          return "未核验";
        case 2:
          return "已核验";
        case 3:
          return "未审核";
        case 4:
          return "已审核";
        case 5:
          return "已发布";
        case 6:
          return "重制中";
        case 99:
          return "已作废";
      }
    },
    getReviewStatus(status) {
      switch (status) {
        case 0:
          return "待审批";
        case 1:
          return "已审批";
        case 2:
          return "已驳回";
        case 3:
          return "已关闭";
      }
    },
    canUpdate(status) {
      if (status === 0) {
        return true;
      }
      return false;
    },
    // canDownload(status){
    //   if(status === 0 || status == 5) {
    //     return true;
    //   }
    //   return false;
    // },
    canVerify(status) {
      if (status == 1) {
        return true;
      }
      return false;
    },
    canPublish(status) {
      if (status == 2) {
        return true;
      }
      return false;
    },
    canWithdraw(status) {
      if (status == 3 || status == 4) {
        return true;
      }
      return false;
    },
    canDelete(status) {
      if (status === 0 || status == 1 || status == 2 || status == 6) {
        return true;
      }
      return false;
    },
    canDestock(status) {
      if (status == 5) {
        return true;
      }
      return false;
    },
    canRemake(status) {
      if (status == 1 || status == 2) {
        return true;
      }
      return false;
    },
    noDownload(status) {
      if (status !== 5) {
        return true;
      }
      return false;
    },
  },
};

export default mixin;
