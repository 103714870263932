<template>
  <div v-if="hasResource('review_info')">
    <div class="download-content">
      <div style="width:17%;left:17%">
        <div
          class="fz-18 cursor-pointer width-107 height-46 mg-b-20 mg-t-50 text-back"
          @click="back"
        >
          <i class="iconfont fz-18 black">
            &#xe647;
            <span class="back-text">返回</span>
          </i>
        </div>
        <div class="download-title">
          <span class="assets-name fz-20 bold">{{ assetName }}</span>
          <span class="mg-l-30">
            <span class="assets-name fz-18 fw-300">{{
              getAssetStatus(assetStatus)
            }}</span>
          </span>
        </div>
        <div
          v-for="(item, index) in assetImg"
          :key="index"
          class="asset-img mg-t-20"
        >
          <img :src="item" />
        </div>
      </div>
      <div style="width:80%;marginLeft:20%;borderLeft:1px solid #efefef;">
        <div>
          <asset-attribute
            :form="{...assetForm, category, tags, searchTags: search_tags, is_sss, is_grouped, is_full_screen, activeColor}"
          />
          <asset-market
            :assetForm="assetForm"
            :category="category"
            :tags="tags"
            :searchTags="search_tags"
          ></asset-market>
          <asset-download
            :assetForm="assetForm"
            :meshFormat="meshFormat"
            :albedoValue="albedoValue"
            :albedo="albedo"
            :aoValue="aoValue"
            :AO="AO"
            :displacementValue="displacementValue"
            :displacement="displacement"
            :metallicValue="metallicValue"
            :metallic="metallic"
            :normal="normal"
            :normalValue="normalValue"
            :roughnessValue="roughnessValue"
            :roughness="roughness"
            :specular="specular"
            :scatter="scatter"
            :fuzz="fuzz"
            :checked="checked"
          />
        </div>
        <div
          style="float:right;margin-top:60px;margin-bottom:70px;marginRight:10px"
        >
          <div class="approval-button">
            <el-button
              class="show-approval-button"
              @click="viewApprovalProcess = true"
              v-if="hasResource('review_history')"
              >查看审批流</el-button
            >
            <el-button
              class="button-approval"
              @click="dialogVisibleApproval = true"
              v-if="this.reviewStatus === 0 && hasResource('review_agree')"
              >审批</el-button
            >
            <el-button
              class="show-approval-button"
              @click="assetReset"
              v-if="this.reviewStatus == 2 && hasResource('review_reset')"
              >重新提交</el-button
            >
            <el-button
              class="button-reject"
              @click="dialogVisibleReject = true"
              v-if="this.reviewStatus === 0 && hasResource('review_reject')"
              >驳回</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- 审批提示弹框 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleApproval"
      width="458px"
      class="add-dialog dialogTip"
    >
      <div class="dialog-content">
        <div class="dialog-title">审批资产</div>
        <div class="dialog-dec">
          您将审批资产
          <span>{{ assetName }}</span>
        </div>
        <div>
          <el-form ref="form" :model="form" :rules="rules" label-width="50px">
            <el-form-item label="备注" prop="name">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="不超过30个字"
                v-model="form.agreeComment"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer pop-dialog">
        <el-button @click="approvalSubmit" class="confirm-button" :disabled="approvalSubmitLoading"
          >确认审批</el-button
        >
        <el-button @click="dialogVisibleApproval = false" class="cancel-button"
          >取 消</el-button
        >
      </span>
    </el-dialog>

    <!-- 驳回资产 -->
    <el-dialog
      top="12%"
      :close-on-click-modal="false"
      :visible.sync="dialogVisibleReject"
      width="458px"
      class="add-dialog dialogTip"
    >
      <div class="dialog-content">
        <div class="dialog-title">驳回资产</div>
        <div class="dialog-dec">
          您将驳回资产
          <span>{{ assetName }}</span>
        </div>
        <div>
          <el-form ref="form" :model="form" :rules="rules" label-width="50px">
            <el-form-item label="备注" prop="name">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="不超过30个字"
                v-model="form.rejectComment"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer pop-dialog">
        <el-button @click="rejectSubmit" class="confirm-button"
          >确认驳回</el-button
        >
        <el-button @click="dialogVisibleReject = false" class="cancel-button"
          >取 消</el-button
        >
      </span>
    </el-dialog>

    <!-- 右侧审批流 -->
    <el-drawer
      :visible.sync="viewApprovalProcess"
      direction="rtl"
      class="approval"
      :show-close="showClose"
    >
      <div class="approval-process">
        <i
          class="el-icon-arrow-right icon"
          @click="viewApprovalProcess = false"
        ></i>
        <div class="title">审批流程</div>
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :color="activity.color"
            :size="activity.size"
            :timestamp="activity.timestamp"
          >
            <span class="status">{{ getStatus(activity.type) }}</span>
            <span class="mg-l-16" v-if="activity.operator">
              <span v-if="activity.type == 0 || activity.type == 998"
                >申请人：</span
              >
              <span v-else>审批人：</span>
              {{ activity.operator }}
            </span>
            <div class="approval-time" v-if="activity.comment">
              备注：
              <span>{{ activity.comment }}</span>
            </div>
            <div class="approval-time">{{ setTime(activity.created_at)}}</div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-drawer>
    <remove-dialog ref="destockPop" :updateList="go"></remove-dialog>
  </div>
</template>

<script>
import RemoveDialog from "../marketAssets/mkt-components/dialogs/rm-dialog";
import Api from "../../../api/asset";
import { formatDate } from 'ecoplants-lib';
import assetStatusMixins from "../../../mixins/asset/status";
import AssetAttribute from "../../../components/asset/asset-attribute";
import AssetMarket from "@/components/asset/asset-market";
import AssetDownload from "../../../components/asset/asset-download";

export default {
  components: {
    RemoveDialog,
    AssetAttribute,
    AssetDownload,
    AssetMarket,
  },
  mixins: [assetStatusMixins],
  data() {
    return {
      form: {
        rejectComment: "",
        agreeComment: "",
        type: "",
      },
      rules: {
        rejectComment: [
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        agreeComment: [
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
      },
      showClose: false,
      // 资产名称
      assetName: "",
      assetStatus: "",
      reviewStatus: "",
      assetImg: "",
      assetImgs: "",
      assetsOriginId: "",

      labelPosition: "left",
      checked: true,
      //查看审批流程
      viewApprovalProcess: false,
      dialogVisibleApproval: false,
      dialogVisibleReject: false,
      activities: [],
      // 数据
      assetForm: {},
      tags: "",
      search_tags: "",
      albedoValue: "",
      albedo: [],
      aoValue: "",
      AO: [],
      displacementValue: "",
      displacement: [],
      metallicValue: "",
      metallic: [],
      normalValue: "",
      normal: [],
      roughnessValue: "",
      roughness: [],
      specular: "",
      scatter: "",
      fuzz: "",
      meshFormat: "",
      category: "",
      activeColor: [],
      is_sss: "",
      is_grouped: "",
      is_full_screen: "",
      approvalSubmitLoading: false // 资产审批弹窗确认按钮状态
    };
  },
  methods: {
    init() {
      let info = {
        id: this.$route.query.id,
        market: "US",
      };
      Api.reviewInfo(info).then((res) => {
        if (res.data.code === 10200) {
          const resData = res.data.data.asset || {};
          this.assetName = res.data.data.asset.name;
          this.assetStatus = res.data.data.asset.status;
          this.assetImgs = res.data.data.asset.previews;
          this.assetForm = res.data.data.asset;
          this.assetForm.modelState = resData.model_state === 1 ? '不封闭' : resData.model_state === 0 ? '封闭' : '';
          this.assetForm.chineseTags = resData.chinese_tags && resData.chinese_tags.length ? resData.chinese_tags.join(',') : '';
          this.tags = res.data.data.asset.tags
            ? res.data.data.asset.tags.toString()
            : "";
          this.activeColor = res.data.data.asset.color || [];
          this.search_tags = res.data.data.asset.search_tags
            ? res.data.data.asset.search_tags.toString()
            : "";
          this.albedo = res.data.data.asset.albedo;
          this.AO = res.data.data.asset.ao;
          this.displacement = res.data.data.asset.displacement;
          this.metallic = res.data.data.asset.metallic;
          this.normal = res.data.data.asset.normal;
          this.roughness = res.data.data.asset.roughness;
          this.specular = res.data.data.asset.specular
            ? res.data.data.asset.specular.toString()
            : "";
          this.scatter = res.data.data.asset.scatter
            ? res.data.data.asset.scatter.toString()
            : "";
          this.fuzz = res.data.data.asset.fuzz
            ? res.data.data.asset.fuzz.toString()
            : "";
          this.meshFormat = res.data.data.asset.mesh_format
            ? res.data.data.asset.mesh_format.toString()
            : "";
          this.is_sss =
            !res.data.data.asset.is_sss? "不需要" : "需要";
          this.is_grouped =
            !res.data.data.asset.is_grouped? "否" : "是";
          this.is_full_screen =
            !res.data.data.asset.is_full_screen? "不支持" : "支持";
          const categroyInfo = res.data.data.asset.categories;
          const categroyData = [];
          categroyInfo.forEach((item, index) => {
            categroyData.push(item.label);
          });
          var assetImg = [];
          this.assetImgs.forEach((item) => {
            assetImg.push(item.url);
          });
          this.assetImg = assetImg;
          this.category = categroyData.toString();
          this.reviewStatus = res.data.data.status;
          this.assetForm.reviewId = res.data.data.id;
          //加载审批流程数据(在此加载，避免每次点击查看审批流按钮调用接口)
          this.approvalProcess();
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    go() {
      this.$router.push("/assets/all");
    },
    /**
    * 添加
        * @param {*} val 值
    @returns{*}
    */
    setTime(val) {
        return formatDate(val * 1000, 'yyyy-MM-dd hh:mm:ss') || '--';
    },
    back() {
      this.$router.push("/assets/approval");
      this.msgBus.$emit("approvalList");
    },
    rejectSubmit() {
      this.dialogVisibleReject = false;
      let arr = [];
      let data = {
        market: "US",
        id: [this.$route.query.id],
        comment: this.form.rejectComment,
      };
      Api.rejectAssets(data).then((resp) => {
        if (resp.data.code == 10200) {
          this.init();
          this.approvalProcess();
          this.$$success("驳回成功");
        } else {
          this.$$error(resp.data.message);
        }
        this.dialogVisibleReject = false;
      });
    },
    approvalSubmit() {
      this.approvalSubmitLoading = true;
      let data = {
        market: "US",
        id: [this.$route.query.id],
        comment: this.form.agreeComment,
      };
      Api.agreeAssets(data).then((resp) => {
        if (resp.data.code == 10200) {
          this.selectAssets = [];
          this.form.agreeComment = '';
          this.dialogApproval = false;
          this.dialogVisibleApproval = false;
          this.$$success("成功");
          this.init();
          this.approvalProcess();
        } else {
          this.$$error(resp.data.message);
        }
        this.approvalSubmitLoading = false;
      });
    },
    approvalProcess() {
      let id = this.assetForm.reviewId;
      let data = "US";
      Api.assetHistory(id, data).then((resp) => {
        if (resp.data.code == 10200) {
          this.activities = resp.data.data;
          // 处理数据
          if (this.activities.length == 1 && this.activities[0].type == 0) {
            let info = {
              created_at: this.activities[0].created_at,
              id: this.activities[0].id,
              operator: localStorage.getItem("username"),
              type: 999,
            };
            this.activities.unshift(info);
          } else if (
            this.activities.length > 1 &&
            this.activities[this.activities.length - 1].type == 2
          ) {
            let info = {
              created_at: this.activities[this.activities.length - 1]
                .created_at,
              operator: this.activities[0].operator,
              type: 998,
            };
            this.activities.unshift(info);
          }
          this.activities[this.activities.length - 1]["color"] = "#EB9719";
        }
      });
    },
    assetReset() {
      this.$router.push({
        path: "/asset/publish",
        query: {
          id: this.assetForm.reviewId,
          type: "approval",
        },
      });
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return "发起申请";
        case 1:
          return "同意审批";
        case 2:
          return "驳回审批";
        case 3:
          return "关闭审批";
        case 4:
          return "重新审批";
        case 999:
          return "待审批";
        case 998:
          return "待提交";
      }
    },
  },
  mounted() {
    this.init();
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="less">
@import "../allAssets/scss/download.less";
@import "../allAssets/scss/index.less";
</style>
