<template>
  <div class="attribute-content">
    <div class="mg-t-50 fz-18 fw-500">基础属性</div>
    <el-row :gutter="24">
      <el-col :span="12" v-for="item in assetAttribute" :key="item.id">
        <el-form
          :inline="true"
          label-position="left"
          label-width="80px"
          :disabled="true"
        >
          <div class="mg-t-30" v-for="child in item.children" :key="child.prop">
            <el-form-item :label="child.label" class="name-input" >
                <template v-if="child.prop === 'activeColor'">
                    <div class="display-flex" v-if="form[child.prop].length > 0">
                        <div
                            :key="color"
                            v-for="color in form[child.prop]"
                            class="width-30 height-30 mg-tl-2 border-1-black"
                            :style="`background:${color}`"
                        ></div>
                    </div>
                    <div v-else class="color-c6">
                        <el-input v-model="asset_color" />
                    </div>
                </template>
                <template v-else>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        :content="form[child.prop]"
                        placement="top"
                        v-if="child.type === 'tooltip' && form[child.prop] && form[child.prop].length > 10"
                    >
                        <el-input v-model="form[child.prop]" />
                    </el-tooltip>
                    <el-input v-else v-model="form[child.prop]" />
                </template>
            </el-form-item>
          </div>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { assetAttribute } from './assetConfig.js';
export default {
  props:  {
    form: {
        type: Object,
        default: () => ({
            activeColor: []
        })
    }
  },
  data() {
    return {
      asset_color: "未选择颜色属性",
      assetAttribute
    };
  },
  mounted() {},
};
</script>
