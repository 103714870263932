/**
 * 基本属性
 * 基于现在的布局，所以写了两层
 */
export const assetAttribute = [{
    id: 1,
    children: [{
        label: '内部ID',
        prop: 'origin_id'
    }, {
        label: '资产类型',
        prop: 'type'
    }, {
        label: '英文名称',
        prop: 'name'
    }, {
        label: '英文标签',
        prop: 'tags',
        type: 'tooltip'
    }, {
        label: '3S效果',
        prop: 'is_sss'
    }, {
        label: '全屏放大',
        prop: 'is_full_screen'
    }, {
        label: '资产分类',
        prop: 'category',
        type: 'tooltip'
    }, {
        label: '是否封闭',
        prop: 'modelState'
    }]
}, {
    id: 2,
    children: [{
        label: '外部ID',
        prop: 'asset_uid'
    }, {
        label: '资产大小',
        prop: 'resource_total_size'
    }, {
        label: '中文名称',
        prop: 'chinese_name'
    }, {
        label: '中文标签',
        prop: 'chineseTags',
        type: 'tooltip'
    }, {
        label: '关键词',
        prop: 'searchTags'
    }, {
        label: '多模型资产',
        prop: 'is_grouped'
    }, {
        label: '颜色属性',
        prop: 'activeColor'
    }, {
        label: '长宽高',
        prop: 'size'
    }]
}];
