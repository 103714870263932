<template>
  <div class="asset-download-content">
    <div class="disable-checkbox">
      <p class="fz-20 line-height-48 chinese-bold col-00 mg-l-40 mg-t-50">
        下载属性
      </p>
      <div class="mg-t-24 mg-l-40">
        <span class="mg-r-47">Resolution</span>
        <el-checkbox
          v-model="checked"
          v-for="(item, index) in assetForm.texture_resolution"
          :key="index"
          disabled
          >{{ item }}</el-checkbox
        >
      </div>
      <div class="mg-t-20">
        <el-form
          :inline="true"
          label-position="left"
          label-width="125px"
          :model="assetForm"
          :disabled="true"
          class="mg-l-40 mg-b-20"
        >
          <el-form-item label="Mesh Format" class="name-input">
            <el-input v-model="meshFormat"></el-input>
          </el-form-item>
        </el-form>
        <div>
          <div
            class="mg-t-20"
            style="marginLeft:165px"
            v-for="(item, index) in assetForm.model_levels"
            :key="index"
          >
            <el-checkbox v-if="index === 0" v-model="checked" disabled>{{
              item
            }}</el-checkbox>
          </div>
          <div class="display-flex" style="marginLeft:165px">
            <div>
              <div
                class="mg-t-20"
                v-for="(item, index) in assetForm.model_levels"
                :key="index"
              >
                <el-checkbox
                  v-if="
                    index === 1 || index === 2 || index === 3 || index === 4
                  "
                  v-model="checked"
                  disabled
                  >{{ item }}</el-checkbox
                >
              </div>
            </div>
            <div class="mg-l-28">
              <div
                class="mg-t-20"
                v-for="(item, index) in assetForm.model_levels"
                :key="index"
              >
                <el-checkbox
                  v-if="
                    index === 5 || index === 6 || index === 7 || index === 8
                  "
                  v-model="checked"
                  disabled
                  >{{ item }}</el-checkbox
                >
              </div>
            </div>
          </div>
          <div class="download-mesh"></div>
          <div style="background:#fcfcfc">
            <div class="pd-t-40 mg-l-40">
              <el-form
                :inline="true"
                label-position="left"
                label-width="125px"
                :model="assetForm"
              >
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item label="Material Preset" class="name-input">
                      <el-input v-model="materialPreset" disabled></el-input>
                    </el-form-item>
                    <div>
                      <div class="mg-t-30">
                        <el-form-item label="Albedo" class="name-input">
                          <el-select v-model="albedoValue" placeholder="PNG">
                            <el-option
                              v-for="(item, index) in albedo"
                              :key="index"
                              :label="item"
                              :value="item"
                              disabled
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="mg-t-30">
                        <el-form-item label="Roughness" class="name-input">
                          <el-select v-model="roughnessValue" placeholder="PNG">
                            <el-option
                              v-for="(item, index) in roughness"
                              :key="index"
                              :label="item"
                              :value="item"
                              disabled
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="mg-t-30">
                        <el-form-item label="Displacement" class="name-input">
                          <el-select
                            v-model="displacementValue"
                            placeholder="PNG"
                          >
                            <el-option
                              v-for="(item, index) in displacement"
                              :key="index"
                              :label="item"
                              :value="item"
                              disabled
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>

                      <div class="mg-t-30">
                        <el-form-item label="Specular" class="name-input">
                          <el-input v-model="specular" disabled></el-input>
                        </el-form-item>
                      </div>
                      <div class="mg-t-30">
                        <el-form-item label="Cavity" class="name-input">
                          <el-select v-model="cavityValue" placeholder="PNG">
                            <el-option
                              v-for="(item, index) in cavity"
                              :key="index"
                              :label="item"
                              :value="item"
                              disabled
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="mg-b-50">
                      <div>
                        <el-form-item label="Normal" class="name-input">
                          <el-select v-model="normalValue" placeholder="PNG">
                            <el-option
                              v-for="(item, index) in normal"
                              :key="index"
                              :label="item"
                              :value="item"
                              disabled
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="mg-t-30">
                        <el-form-item label="Metallic" class="name-input">
                          <el-select v-model="metallicValue" placeholder="PNG">
                            <el-option
                              v-for="(item, index) in metallic"
                              :key="index"
                              :label="item"
                              :value="item"
                              disabled
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="mg-t-30">
                        <el-form-item label="AO" class="name-input">
                          <el-select v-model="aoValue" placeholder="PNG">
                            <el-option
                              v-for="(item, index) in AO"
                              :key="index"
                              :label="item"
                              :value="item"
                              disabled
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="mg-t-30">
                        <el-form-item label="Gloss" class="name-input">
                          <el-select v-model="glossValue" placeholder="PNG">
                            <el-option
                              v-for="(item, index) in gloss"
                              :key="index"
                              :label="item"
                              :value="item"
                              disabled
                            ></el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                      <div class="mg-t-30">
                        <el-form-item label="Scatter" class="name-input">
                          <el-input v-model="scatter" disabled></el-input>
                        </el-form-item>
                      </div>
                      <div class="mg-t-30">
                        <el-form-item label="Fuzz" class="name-input">
                          <el-input v-model="fuzz" disabled></el-input>
                        </el-form-item>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "assetForm",
    "meshFormat",
    "albedo",
    "albedoValue",
    "AO",
    "materialPreset",
    "aoValue",
    "displacement",
    "displacementValue",
    "metallic",
    "metallicValue",
    "normal",
    "normalValue",
    "roughnessValue",
    "roughness",
    "specular",
    "scatter",
    "fuzz",
    "cavity",
    "gloss",
    "cavityValue",
    "glossValue",
    "checked",
  ],
  data() {
    return {};
  },
  methods: {},
};
</script>
