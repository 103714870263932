<template>
  <div class="asset-market-content">
    <div class="fz-18 fw-500">市场属性</div>
    <el-row :gutter="24">
      <el-col :span="12">
        <el-form
          :inline="true"
          label-position="left"
          label-width="100px"
          :model="assetForm"
          :disabled="true"
        >
          <!-- <div class="mg-t-30">
            <el-form-item label="发布市场" class="name-input">
              <el-input v-model="assetForm.market" />
            </el-form-item>
          </div> -->
          <div class="mg-t-30">
            <el-form-item label="发布时间" class="name-input">
              <el-input v-model="published_at" />
            </el-form-item>
          </div>
        </el-form>
      </el-col>
      <el-col :span="12">
        <el-form
          :inline="true"
          label-position="left"
          label-width="100px"
          :model="assetForm"
          :disabled="true"
        >
          <!-- <div class="mg-t-30">
            <el-form-item label="下载价格" class="name-input">
              <el-input v-model="assetForm.cost" />
            </el-form-item>
          </div> -->
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { formatDate } from 'ecoplants-lib';
export default {
  props: ["assetForm", "category", "tags", "searchTags"],
  data() {
    return {};
  },
  computed: {
    published_at() {
        return this.assetForm.published_at ? formatDate(this.assetForm.published_at * 1000, 'yyyy-MM-dd hh:mm:ss') : '--';
    }
  },
  methods: {},
};
</script>
